import React from "react";
import GetSVG from "../svg";

// Feature Card
const Feature = (props) => {
  const {
    svgName,
    cardHeading,
    cardDesctiption,
    link,
    linkText,
    secondLink,
    secondLinkText,
  } = props;

  return (
    <div className="feature-card-v2">
      <div className="text-box">
        <div className="card-icon-section">
          <GetSVG svgName={svgName} />
        </div>
        <div className="card-heading-section">
          <h3>{cardHeading}</h3>
        </div>
        <div className="card-desc-section">
          <p>{cardDesctiption} </p>
          {secondLink && (
            <a href={secondLink} target="_blank" rel="noreferrer">
              {secondLinkText}
            </a>
          )}
        </div>
      </div>
      <div className="link-box">
        <a href={link} target="_blank" rel="noreferrer">
          {linkText}
        </a>
      </div>
    </div>
  );
};

export default Feature;
