import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const clients = [
  {
    id: "bookMyShow",
    image: () => (
      <StaticImage
        src="../../static/company-logos/bookmyshow-logo.jpg"
        alt="BookMyShow Logo"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    id: "time",
    image: () => (
      <StaticImage
        src="../../static/company-logos/time-logo.jpg"
        alt="Time Logo"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    id: "melbourne-festival",
    image: () => (
      <StaticImage
        src="../../static/company-logos/film-festival-melbourne.png"
        alt="Melbourne Festival Logo"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    id: "caClubIndia",
    image: () => (
      <StaticImage
        src="../../static/company-logos/mcq.png"
        alt="CA Club India Logo"
        placeholder="blurred"
        quality={100}
      />
    ),
  },

  {
    id: "justVisionLogo",
    image: () => (
      <StaticImage
        src="../../static/company-logos/justvision-logo.jpg"
        alt="JustVision Logo"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    id: "jigSawLogo",
    image: () => (
      <StaticImage
        src="../../static/company-logos/jigsaw-logo.jpg"
        alt="Jigshaw Logo"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
];
function BrandsLogo() {
  return (
    <div className="row container-wrap">
      <div className="business-trust row">
        <div className="col m12 s12 l12">
          <p className="brand-info">
            3000+ Businesses have streamed 260 Million hours of videos across
            180+ Countries with VdoCipher
          </p>
        </div>
      </div>
      <div className="brands">
        {clients.map((client) => {
          return (
            <div
              key={client.id}
              className={`logo-image-holder holder-${client.id}`}
            >
              {client.image()}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BrandsLogo;
