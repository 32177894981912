import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import GetSVG from "../svg";
import { getLoginId } from "../utils/user/info-utils";

const FeaturesList = () => {
  const [showDashboardButton, setShowDashboardButton] = useState(false);
  useEffect(() => {
    if (getLoginId()) {
      setShowDashboardButton(true);
    }
  }, []);

  return (
    <div className="feature-list-v2">
      {/* ROW-0 */}
      <div
        className="full-width-div even row-0"
        style={{ marginBottom: "65px" }}
      >
        <div className="row max-width-div">
          <div className="col l6 m6 s12">
            <div className="svg-text-holder">
              <GetSVG svgName="dotSquare" />
              <div className="text-area">
                <h3>Get Started with a 30-Day Trial for FREE!</h3>
                <p>
                  Get full access to all our security features and start growing
                  your business today.
                </p>
                <a href="/dashboard/register">
                  <div className="btn free-trial-button">
                    {showDashboardButton
                      ? "Dashboard"
                      : "START FREE 30-DAY TRIAL"}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="img-container col l6 m6 s12">
            <StaticImage
              alt="Start with VdoCipher Free Trial "
              placeholder="blurred"
              quality={100}
              src="../../static/page-assets/start-free-trial.png"
              height={450}
            />
          </div>
        </div>
      </div>
      {/* ROW-1 */}
      <div className="full-width-div odd row-1">
        <div className="row max-width-div">
          <div className="img-container col l6 m6 s12">
            <StaticImage
              alt="Increase revenue from your own videos with vdocipher"
              placeholder="blurred"
              quality={100}
              src="../../static/page-assets/increase-revenue.png"
              height={450}
            />
          </div>
          <div className="col l6 m6 s12">
            <div className="svg-text-holder">
              <div className="text-area">
                <h3>Increase Revenue from Your Own Videos</h3>
                <p>
                  VdoCipher gives a boost to your revenue by preventing any
                  illegal video download. Our customers see a jump in their
                  revenue upto 81% in a year.
                </p>
                <a href="/dashboard/register">
                  <div className="secBtn btn ">
                    {showDashboardButton
                      ? "Dashboard"
                      : "START FREE 30-DAY TRIAL"}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ROW-2 */}
      <div className="full-width-div even row-2">
        <div className="row max-width-div">
          <div className="col l6 m6 s12">
            <div className="svg-text-holder">
              <div className="text-area">
                <h3>Secure Videos by Preventing illegal Video Downloads</h3>
                <p>
                  Our DRM based secure video delivery is unmatched and ensures
                  that your content is protected from any illegal video
                  download. We further strengthen our security with screen
                  recording protection and Dynamic Watermarking.
                </p>
                <a href="/dashboard/register">
                  <div className="btn free-trial-button">
                    {showDashboardButton ? "Dashboard" : "Secure Videos"}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="img-container col l6 m6 s12">
            <StaticImage
              alt="Secure your videos with VdoCipher"
              placeholder="blurred"
              quality={100}
              src="../../static/page-assets/secure-videos.png"
              height={450}
            />
          </div>
        </div>
      </div>
      {/* ROW-3 */}
      <div className="full-width-div odd row-3">
        <div className="row max-width-div">
          <div className=" img-container col l6 m6 s12">
            <StaticImage
              alt="Vdocipher is built for E-Learning and LMS Plateforms"
              placeholder="blurred"
              quality={100}
              src="../../static/page-assets/for-e-learning.png"
              height={450}
            />
          </div>
          <div className="col l6 m6 s12">
            <div className="svg-text-holder">
              <div className="text-area">
                <h3>Built for e-Learning, Media & LMS Platforms</h3>
                <p>
                  If you are an e-learning or media platform, look no further!
                  VdoCipher’s online video security helps several such platforms
                  scale their video streaming, secure their videos and deliver
                  them smoothly to their users.
                </p>
                <a href="/dashboard/register">
                  <div className="secBtn btn">
                    {showDashboardButton ? "Dashboard" : "GET STARTED NOW"}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesList;
