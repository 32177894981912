import React from "react";
import AboutBannerImg from "../img/aboutbanner.png";

const AboutBanner = () => {
  return (
    <div
      className="container my-4 banner-wrapper"
      style={{ background: "#ebecfe" }}
    >
      <div className="row" style={{ margin: "0px" }}>
        <div className="col l5 m4 s12 text-center">
          <img
            src={AboutBannerImg}
            alt="About Banner"
            className="img-fluid"
            style={{ maxWidth: "240px" }}
          />
        </div>
        <div className="col l7 m8 s12">
          <h2>VdoCipher Team, Culture & Vision</h2>
          <p>
            VdoCipher strives to maintain a joyful work culture and contributes
            regularly to social well-being for underprivileged.
          </p>
          <a href="/page/about/" target="_blank">
            <button className="btn banner-button">
              Learn More about the Company
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;
