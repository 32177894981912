import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const cardData = [
  {
    id: "European Reader",
    image: () => (
      <StaticImage
        src="../../static/customers/european-readers-choice-award.png"
        alt="European"
        placeholder="blurred"
        quality={100}
        width={110}
      />
    ),
    cardTitle:
      "Best Video Security Company Award by Streaming Media Magazine, Europe",
    // cardSubTitle: "Award by Streaming Media Magazine, Europe",
  },
  {
    id: "Widevine",
    image: () => (
      <StaticImage
        src="../../static/customers/vdocipher-widevine.png"
        alt="Widevine"
        placeholder="blurred"
        quality={100}
        width={110}
      />
    ),
    cardTitle: "Direct Partner with Google for Widevine DRM",
    // cardSubTitle: "Google for Widevine DRM",
  },
  {
    id: "g2",
    image: () => (
      <StaticImage
        src="../../static/customers/g2logo.png"
        alt="Widevine"
        placeholder="blurred"
        quality={100}
        width={54}
      />
    ),
    cardTitle: "4.9 Rating on G2 Crowd Review ",
    // cardSubTitle: "Platform and Widevine partner",
  },
];

const Award = () => {
  return (
    <div className="row award-section">
      {cardData.map((cardItem) => {
        return (
          <div key={cardItem.id}>
            <div className="col l4 m12 s12">
              <div className="award-card-holder">
                <div
                  className="award-card"
                  style={{
                    padding: "20px",
                    height: "94px",
                  }}
                >
                  <div className="static-img-holder">{cardItem.image()}</div>
                  <div className="award-text-holder">
                    <p
                      className="award-name"
                      style={{
                        paddingLeft: "20px",
                        fontSize: "14px",
                      }}
                    >
                      {cardItem.cardTitle}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Award;
