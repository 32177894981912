import React, { useEffect, useState } from "react";
import BackgroundIcons from "./bg-icon";
import BrandsLogo from "./brands";
import MainContent from "./sub-content";
if (process.env.WEBPACK) require("../styles/common.scss");

function NewJumbotron() {
  const texts = [" Courses", " LMS", " Movies", " Lectures"];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        if (currentIndex <= texts[currentTextIndex].length) {
          setDisplayText(texts[currentTextIndex].substring(0, currentIndex));
          setCurrentIndex(currentIndex + 1);
        } else {
          setIsPaused(true);
          setTimeout(() => {
            setCurrentIndex(0);
            setCurrentTextIndex((currentTextIndex + 1) % texts.length);
            setDisplayText("");
            setIsPaused(false);
          }, 2000); // 2-second pause
        }
      }
    }, 200); // Change the interval for typing speed

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, currentTextIndex, texts, isPaused]);

  return (
    <div className="new-jumbotron">
      <div className="main-content">
        <div className="row container-wrap">
          <h1>Secure Video Hosting</h1>
          <h2 className="flxcenter mobile-hidden">
            Bye-Bye Piracy: Boost Revenues for&nbsp;
            <span className="typing"> {displayText}</span>
          </h2>
          <h2 className="dekstop-hidden flxcenter">
            Bye-Bye Piracy: Boost Revenues for <br />
            Videos
          </h2>

          <MainContent />
          <div className="button-group flxcenter">
            <a href="/dashboard/register">
              <button className="main-btn btn-purple">Secure Videos Now</button>
            </a>
            <a href="/site/contact" target="_blank">
              <button className="main-btn btn-green">Schedule Demo</button>
            </a>
          </div>
        </div>
      </div>
      <BackgroundIcons />
      <BrandsLogo />
    </div>
  );
}

export default NewJumbotron;
