import React, { useState, useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import GetSVG from "../svg";
import "../styles/common.scss";

const messages = [
  "130,000+ Piracy attempts blocked, 1100+ Hackers identified in past 6 months by our Piracy Blocker Engine",
  "3000+ Businesses globally secured extra $200 Million in revenues by preventing piracy & increasing paid users",
];

const benefits = [
  "Prevent illegal Downloads: DRM, Watermark, Piracy Blocker",
  "Custom Player for Your E-learning or Media Site & App",
  "Go Beyond DRM: Revenue Protection by Real-time Pirate Identification",
];

const brands = [
  {
    id: "bookMyShow",
    image: () => (
      <StaticImage
        src="../../static/company-logos/bookmyshow-logo.jpg"
        alt="BookMyShow Logo"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    id: "time",
    image: () => (
      <StaticImage
        src="../../static/company-logos/time-logo.jpg"
        alt="Time Logo"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    id: "melbourne-festival",
    image: () => (
      <StaticImage
        src="../../static/company-logos/film-festival-melbourne.png"
        alt="Melbourne Festival Logo"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    id: "caClubIndia",
    image: () => (
      <StaticImage
        src="../../static/company-logos/mcq.png"
        alt="CA Club India Logo"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    id: "justVisionLogo",
    image: () => (
      <StaticImage
        src="../../static/company-logos/justvision-logo.jpg"
        alt="JustVision Logo"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
  {
    id: "jigSawLogo",
    image: () => (
      <StaticImage
        src="../../static/company-logos/jigsaw-logo.jpg"
        alt="Jigsaw Logo"
        placeholder="blurred"
        quality={100}
      />
    ),
  },
];

const BenefitComponent = ({ children }) => (
  <div className="benefit-text">
    <div className="checkPoints">
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "12px" }}
      >
        <GetSVG svgName="tickMarkIcon" />
      </div>
      <p className="revenue">{children}</p>
    </div>
  </div>
);

const Jumbotron = ({ showDashboardButton }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState("fade-in");
  const showBanner = false;
  const intervalRef = useRef();
  const timeoutRef = useRef();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setFadeClass("fade-out");

      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
        setFadeClass("fade-in");
      }, 500);
    }, 9000);

    return () => {
      clearInterval(intervalRef.current);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div className="jumbotron-v2">
      <div className="cover">
        {showBanner && (
          <div className="event-banner">
            <Link to="/site/pricing?utm_source=home_banner&utm_medium=blackfriday&utm_campaign=dscnt">
              <div className="event-link">
                <h4 style={{ fontSize: "1rem" }}>
                  Hurry Up! Black Friday Discount for New Signups:&nbsp;
                  <span style={{ color: "#6962dd" }}>Avail here!</span>
                </h4>
              </div>
            </Link>
          </div>
        )}
        <div className="content">
          <div className="row max-width-div">
            <div className="jumbotron-text-area button-wrap col l6 m12 s12">
              <div className="banner-cont">
                <div className="banner-heading" style={{ marginTop: "15px" }}>
                  <h1>Secure Video Hosting</h1>
                </div>
                <div className="banner-description">
                  {benefits.map((benefit, index) => (
                    <BenefitComponent key={index}>{benefit}</BenefitComponent>
                  ))}
                </div>
              </div>
              <div
                className="button-holder button-wrap-align"
                style={{ marginBottom: "40px" }}
              >
                <a href="/dashboard/register">
                  {showDashboardButton ? (
                    <button className="btn register">DASHBOARD</button>
                  ) : (
                    <button className="btn register">Secure Videos Now</button>
                  )}
                </a>
                <a href="/site/contact">
                  <button className="btn register download-challenge-btn">
                    Schedule Demo
                  </button>
                </a>
                <div className="credit-req mobile-hidden">
                  * 30 Days Free | No Credit Card Required
                </div>
                <div
                  className="partner"
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #e5e5e5",
                    padding: "8px",
                    maxWidth: "425px",
                  }}
                >
                  <StaticImage
                    src="../../static/customers/vdocipher-widevine.png"
                    alt="widewine"
                    placeholder="blurred"
                    quality={100}
                    width={80}
                  />
                  <p
                    style={{
                      paddingLeft: "10px",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Direct Partner with Google for Widevine DRM
                  </p>
                </div>
              </div>
            </div>
            <div className="jumbotron-header-image-holder col l6 m12 s12">
              <div className="home-video">
                <iframe
                  src="https://player.vdocipher.com/v2/?otp=20160313versUSE3136aEPjkCfohZbCo7JXBaER0ktCCDUsK80TtAvCMr5yC9zzQ&playbackInfo=eyJ2aWRlb0lkIjoiOTUwZjkzZDkzMjE2NDFmMzllZWExOTYzYjM3ZDM3MDQifQ=="
                  allowFullScreen="true"
                  allow="encrypted-media"
                  style={{
                    border: 0,
                    maxWidth: "800px",
                  }}
                ></iframe>
              </div>
            </div>
          </div>
          <div className="row max-width-div">
            <div className="business-trust row">
              <div className="col m12 s12 l12">
                <p
                  className={`business-trust-info ${fadeClass}`}
                  style={{ maxWidth: "840px", margin: "auto" }}
                >
                  {messages[currentIndex]}
                </p>
              </div>
            </div>
            <div className="brands">
              {brands.map((brand) => (
                <div
                  key={brand.id}
                  className={`logo-image-holder holder-${brand.id}`}
                >
                  {brand.image()}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jumbotron;
