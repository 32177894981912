import React from "react";
import securityImg from "../img/video-security.png";

const SecurityBanner = () => {
  return (
    <div
      className="container my-4 banner-wrapper"
      style={{ background: "#fef0ef" }}
    >
      <div className="row" style={{ margin: "0px" }}>
        <div className="col l9 m8 s12">
          <h2>Looking for Video Security beyond DRM</h2>
          <p>
            VdoCipher not only provides DRM but also provides Piracy Tracker &
            Hacker Identification tech to counter advanced DRM hacking attempts.
            Blocked 57,000+ sessions & 290 users across 2000+ website/apps in 6
            months.
          </p>
          <a
            href="/blog/drm-plus-features/?utm_source=home&utm_medium=home_cta&utm_campaign=beyond_drm"
            target="_blank"
          >
            <button className="btn banner-button">Learn More</button>
          </a>
        </div>
        <div className="col l3 m4 s12 text-center">
          <img src={securityImg} alt="Security" className="img-fluid " />
        </div>
      </div>
    </div>
  );
};

export default SecurityBanner;
