import React, { useEffect, useState } from "react";
import GetSVG from "../svg";
import { getLoginId } from "../utils/user/info-utils";
import FAQCard from "./faq-card";
import faqs from "./faqs";

// Summary and FAQ section

const FAQAndSummary = () => {
  const [showDashboardButton, setShowDashboardButton] = useState(false);
  useEffect(() => {
    if (getLoginId()) {
      setShowDashboardButton(true);
    }
  }, []);

  return (
    <div className="faq-and-summary-section-v2">
      <div className="max-width-div">
        <div className="faq-header-container">
          <h2>FAQ</h2>
        </div>
        <div className="faq-body-container row">
          <div className="col l6 m12 s12">
            {/* expandable list */}
            <div style={{ paddingTop: "30px" }}>
              <FAQCard faqList={faqs()} />
            </div>
          </div>
          <div className="right-side-div col l6 m12 s12">
            <div className="dots-and-card-holder">
              <GetSVG svgName="blueDots" className="blue-dot-svg" />

              {/* Start free trial card */}
              <div className="start-free-trial-card">
                <div className="free-trial-card-header">
                  <span>81%</span>
                </div>
                <div className="free-trial-card-body">
                  <div className="card-desc-text">
                    Average increase in Revenue Surveyed Customers agreed owing
                    to VdoCipher
                  </div>
                  <div className="btn-holder">
                    <a href="/case-study-india">
                      <div className="free-trial-button btn ">
                        Read Our Case Study
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* bottom horizontal card */}

        <div className="horizontal-trial-card-holder">
          <div className="horizontal-trial-card row">
            <div className="trial-card-text col l6 m6 s12">
              <div className="heading">
                <p>Sign Up & Get Started Now!</p>
              </div>
              <div className="no-credit-card-req">
                <p>* 30 Days Free | No Credit Card Required</p>
              </div>
            </div>

            <div className="trial-card-buttons col l6 m6 s12">
              <a href="/dashboard/register">
                <div className="btn">
                  {showDashboardButton ? "Dashboard" : "Start Free Trial!"}
                </div>
              </a>
              <a href="/site/pricing" target="_blank">
                <div className="btn explore-plan-btn">Explore Plans</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FAQAndSummary;
