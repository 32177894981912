import React from "react";
import downloader from "../../static/customers/downloader.jpg";

const Downloader = () => {
  return (
    <div className="downloaderWrap">
      <div className="row downloaderSection spaceremove">
        <div className="row">
          <div className="col l7 m4 s12 ">
            <div className="downloaderContent">
              <h2>Downloaders with 100 Million+ Installs Fail on VdoCipher!</h2>
              <a href="/video-security" target="_blank">
                <button className="btn register download-challenge-btn">
                  Test VdoCipher Security
                </button>
              </a>
            </div>
          </div>
          <div className="col l5 m4 s12 downloaderImage">
            <img src={downloader} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Downloader;
